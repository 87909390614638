import React, { useState, useEffect } from "react";
import {
  ref as dbRef,
  onValue,
  query,
  orderByChild,
  update,
  get,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { db, auth } from "../firebase";

import "../components/pastorder/ActiveOrders.css";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ActiveOrdersList from "../components/pastorder/ActiveOrdersList";
import OrderProgress from "../components/pastorder/OrderProgress";
import MainDetails from "../components/pastorder/MainDetails";
import MedicineListDetails from "../components/pastorder/MedicineListDetails";
import PharmacyConfirmationDetails from "../components/pastorder/PharmacyConfirmationDetails";
import OrderStatus from "../components/pastorder/OrderStatus";

const PastOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [billImageUrl, setBillImageUrl] = useState("");
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);
  const [error, setError] = useState("");
  const excludedStatuses = [
    "Ticket Closed by Customer Care",
    "Cancelled/Abandoned by customer",
  ];

  const ispast = true;

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const ordersRef = dbRef(db, "order_table");
      const activeOrdersQuery = query(ordersRef, orderByChild("order_status"));

      onValue(
        activeOrdersQuery,
        (snapshot) => {
          const ordersData = snapshot.val();
          if (ordersData) {
            const activeOrders = Object.entries(ordersData)
              .map(([id, order]) => ({
                ...order,
                order_id: id,
              }))
              .filter(
                (order) =>
                  order.pharmacy_id &&
                  excludedStatuses.includes(order.order_status)
              );
            console.log(activeOrders);

            setOrders(activeOrders);
          } else {
            setOrders([]);
          }
        },
        (error) => {
          console.error("Error fetching orders:", error);
        }
      );
    }
  }, [excludedStatuses]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user && selectedOrder) {
      const orderRef = dbRef(db, `order_table/${selectedOrder.order_id}`);

      const unsubscribe = onValue(
        orderRef,
        (snapshot) => {
          const updatedOrder = snapshot.val();
          if (updatedOrder) {
            setSelectedOrder({
              ...updatedOrder,
              order_id: selectedOrder.order_id,
            });
          }
        },
        (error) => {
          console.error("Error fetching order:", error);
        }
      );

      // Clean up the listener when the component unmounts or selectedOrder changes
      return () => unsubscribe();
    }
  }, [selectedOrder]);

  const handleSelectOrder = (orderId) => {
    const order = orders.find((o) => o.order_id === orderId);
    setSelectedOrder(order);
  };

  const handleUpdateOrder = (orderId, updatedData) => {
    const orderRef = dbRef(db, `order_table/${orderId}`);
    update(orderRef, updatedData)
      .then(() => {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === orderId ? { ...order, ...updatedData } : order
          )
        );
        if (selectedOrder && selectedOrder.order_id === orderId) {
          setSelectedOrder((prevOrder) => ({ ...prevOrder, ...updatedData }));
        }
      })
      .catch((error) => {
        console.error("Error updating order:", error);
      });
  };

  const openBillModal = async () => {
    if (!selectedOrder) return;
    setError("");
    try {
      const billUrlRef = dbRef(
        db,
        `order_table/${selectedOrder.order_id}/medicine_bill`
      );
      const snapshot = await get(billUrlRef);
      if (snapshot.exists()) {
        const url = snapshot.val();
        setBillImageUrl(url);
        setIsBillModalOpen(true);
      } else {
        setError("Medicine bill not found.");
      }
    } catch (error) {
      console.error("Error fetching medicine bill:", error);
      setError("Failed to load medicine bill. Please try again.");
    }
  };

  const closeBillModal = () => {
    setIsBillModalOpen(false);
  };
  const downloadImage = () => {
    if (!billImageUrl) return;
    const link = document.createElement("a");
    link.href = billImageUrl;
    link.download = `medicine_bill_${selectedOrder.order_id}.jpg`; // You can customize the file name and extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="active-orders-container">
      <div className="orders-list-panel">
        <ActiveOrdersList
          orders={orders}
          onSelectOrder={handleSelectOrder}
          selectedOrderId={selectedOrder?.order_id}
        />
      </div>
      {selectedOrder && (
        <div className="order-details-panel">
          <div className="order-progress flex-1">
            <h3>New Orders Progress</h3>
            <OrderProgress
              status={selectedOrder.order_status}
              orderId={selectedOrder.order_id}
            />
          </div>
          <div className="">
            <div className="main-details">
              <MainDetails
                ispast={ispast}
                order={selectedOrder}
                onUpdateOrder={handleUpdateOrder}
              />
            </div>
            <div className="medicine-list">
              <h3>Medicine List</h3>
              <MedicineListDetails medicines={selectedOrder.medicines} />
            </div>
            <div className="pharmacy-confirmation">
              <PharmacyConfirmationDetails
                ispast={ispast}
                onUpdateOrder={handleUpdateOrder}
                order={selectedOrder}
                pharmacyConfirmation={selectedOrder.pharmacy_confirmation}
              />
              <OrderStatus
                status={selectedOrder.order_status}
                orderId={selectedOrder.order_id}
              />
              {
                <button
                  onClick={openBillModal}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4"
                >
                  View Medicine Bill
                </button>
              }
            </div>
          </div>
        </div>
      )}

      {isBillModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full h-full flex flex-col">
            <div className="bg-gray-200 px-4 py-2 flex justify-between items-center">
              <h3 className="text-xl font-bold">Medicine Bill</h3>
              <div className="flex space-x-2">
                <button
                  onClick={downloadImage}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded"
                >
                  Download
                </button>
                <button
                  onClick={closeBillModal}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded"
                >
                  Close
                </button>
              </div>
            </div>
            <div className="flex-grow overflow-hidden relative">
              <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
              >
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <>
                    <div className="absolute top-2 left-2 z-10 space-x-2">
                      <button
                        onClick={() => zoomIn()}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded"
                      >
                        Zoom In
                      </button>
                      <button
                        onClick={() => zoomOut()}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded"
                      >
                        Zoom Out
                      </button>
                      <button
                        onClick={() => resetTransform()}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded"
                      >
                        Reset
                      </button>
                    </div>
                    <TransformComponent>
                      <div className="w-full h-full flex items-center justify-center">
                        {billImageUrl ? (
                          <img
                            src={billImageUrl}
                            alt="Medicine Bill"
                            className="max-w-full max-h-full object-contain"
                          />
                        ) : (
                          <p className="text-center">
                            Loading medicine bill...
                          </p>
                        )}
                      </div>
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PastOrders;
