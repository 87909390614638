import React, { useState } from "react";
import { ref, update } from "firebase/database";

import { toast } from "react-toastify";
import { db } from "../../firebase";

const MainDetails = ({ order, ispast }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedOrder, setEditedOrder] = useState(order);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedOrder((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Submitting changes..."); // Debugging: Submission started
      const orderRef = ref(db, `order_table/${order.order_id}`);
      await update(orderRef, editedOrder);
      console.log("Document updated!"); // Debugging: Data successfully updated

      setIsEditModalOpen(false); // Attempt to close the modal
      console.log("Modal closed!"); // Debugging: Confirm if closeEditModal() is executed
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const cancelOrder = async () => {
    try {
      const orderRef = ref(db, `order_table/${order.order_id}`);
      await update(orderRef, {
        order_status: "Cancelled/Abandoned by customer",
      });
      toast.error("Order cancelled successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.log("Document updated!"); // Debugging: Data successfully updated
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const renderDetailRow = (label, value) => (
    <div className="grid grid-cols-2 gap-4 py-2">
      <div className="font-semibold text-gray-600">{label}:</div>
      <div className="text-gray-800">{value || "N/A"}</div>
    </div>
  );

  return (
    <div className="bg-white  rounded-lg overflow-hidden">
      <div className="float-right mb-4">
        {ispast ? null : (
          <button
            onClick={cancelOrder}
            className="bg-red-200 text-red-600 p-2 font-semibold px-6 rounded-md hover:bg-red-300"
          >
            Cancel Order
          </button>
        )}
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {[
              "Order ID",
              "Status",
              "Phone",
              "Address (lat, long)",
              "Name",
              "Full Address",
              "Image",
              "Actions",
            ].map((header) => (
              <th
                key={header}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-6 py-4 whitespace-nowrap">{order.order_id}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {order.order_status}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {order.phone_number}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{order.location}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {order.customer_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {order.full_address}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {order.bill_file && (
                <img
                  src={order.bill_file}
                  alt="Order"
                  className="h-10 w-10 rounded-full object-cover"
                />
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
              <button
                onClick={toggleExpand}
                className="text-indigo-600 bg-indigo-200 p-2 hover:text-indigo-900 mr-2 rounded-md"
              >
                {isExpanded ? "Collapse" : "Expand"}
              </button>

              {ispast ? null : (
                <button
                  onClick={openEditModal}
                  className="text-green-600 bg-green-200 p-2 hover:text-green-900 rounded-md"
                >
                  Edit
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {isExpanded && (
        <div className="p-6 bg-gray-50">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <h4 className="text-lg font-semibold mb-4">
                Additional Order Details
              </h4>
              {renderDetailRow("Customer Name", order.customer_name)}
              {renderDetailRow("Billing Name", order.billing_name)}
              {renderDetailRow("Full Address", order.full_address)}
              {renderDetailRow("Order Status", order.order_status)}
              {renderDetailRow("Delivery Cost", order.delivery_cost)}
              {renderDetailRow("Pharmacy Name", order.pharmacy_name)}
              {renderDetailRow(
                "Pharmacy Contact",
                order.pharmacy_contact_number
              )}
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Prescription Info</h4>
              {renderDetailRow("Doctor Name", order.doctor_name)}
              {renderDetailRow(
                "Clinic/Hospital Name",
                order.clinic_hospital_name
              )}
              {renderDetailRow("Doctor Notes", order.doctor_notes)}
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Additional Details</h4>
              {renderDetailRow("Discount Percentage", order.discount_perc)}
              {renderDetailRow("Discount Amount", order.discount_amt)}
              {renderDetailRow(
                "Total Billing Amount",
                order.total_billing_amount
              )}
              {renderDetailRow("Payment ID", order.payment_id)}
              {renderDetailRow("Delivery ID", order.delivery_id)}
              {renderDetailRow("Pharmacy ID", order.pharmacy_id)}
            </div>
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <div className="absolute inset-0 z-50  bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-3/4 max-w-4xl shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Edit Order
              </h3>
              <form onSubmit={handleSubmit} className="mt-2">
                <div className="grid grid-cols-3 gap-4">
                  {Object.entries(editedOrder).map(([key, value]) => {
                    console.log(key, value);
                    // Skip these fields
                    if (
                      [
                        "medicines",
                        "pharmacy_confirmation",
                        "bill_file",
                        "medicine_bill ",
                        "order_creation_datetime",
                      ].includes(key)
                    ) {
                      return null;
                    }
                    return (
                      <div key={key} className="mb-4">
                        <label
                          htmlFor={key}
                          className="block text-sm font-medium text-gray-700"
                        >
                          {key.replace(/_/g, " ").charAt(0).toUpperCase() +
                            key.replace(/_/g, " ").slice(1)}
                        </label>
                        <input
                          disabled={
                            key === "pharmacy_id" ||
                            key === "order_status" ||
                            key === "order_id"
                          }
                          type="text"
                          name={key}
                          id={key}
                          value={value}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={closeEditModal}
                    className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainDetails;
