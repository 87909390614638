import React, { useState, useEffect } from 'react';
import { ref, onValue, push, set, remove, query, orderByChild, equalTo, get } from 'firebase/database';
import { db } from '../firebase';
import { SearchBar } from './pharmacy/SearchBar';
import { PharmacyTable } from './pharmacy/PharmacyTable';
import { Pagination } from './pharmacy/Pagination';
import { AddEditPharmacyModal } from './pharmacy/AddEditPharmacyModal';
import { ViewPharmacyModal } from './pharmacy/ViewPharmacyModal';
import { DeleteConfirmationModal } from './pharmacy/DeleteConfirmationModal';
import { toast } from 'react-toastify';


function PharmacyPage() {
  const [pharmacies, setPharmacies] = useState([]);
  const [filteredPharmacies, setFilteredPharmacies] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const pharmaciesRef = ref(db, 'pharmacy_details');
    const unsubscribe = onValue(pharmaciesRef, (snapshot) => {
      const data = snapshot.val();
      const pharmacyList = data ? Object.entries(data).map(([key, value]) => ({ id: key, ...value })) : [];
      setPharmacies(pharmacyList);
      setFilteredPharmacies(pharmacyList);
    });

    return () => unsubscribe();
  }, []);

  const handleSearch = async (searchTerm, searchBy) => {
    if (!searchTerm) {
      setFilteredPharmacies(pharmacies);
      return;
    }

    const pharmaciesRef = ref(db, 'pharmacy_details');
    const searchQuery = query(pharmaciesRef, orderByChild(searchBy), equalTo(searchTerm));
    const snapshot = await get(searchQuery);
    const data = snapshot.val();
    const searchResults = data ? Object.entries(data).map(([key, value]) => ({ id: key, ...value })) : [];
    setFilteredPharmacies(searchResults);
  };

  const handleAddPharmacy = () => {
    setSelectedPharmacy(null);
    setIsAddModalOpen(true);
  };

 const handleSubmitPharmacy = async (formData) => {
  try {
    const pharmaciesRef = ref(db, 'pharmacy_details');
    
    // Push pharmacy data to 'pharmacy_details'
    const newPharmacyRef = await push(pharmaciesRef, formData);
    const newPharmacyId = formData.pharmacy_uid

    // Create a new entry in the 'users' table
    const usersRef = ref(db, `users/${newPharmacyId}`);
    const userData = {
      email: formData.login_email || '',
      name: formData.pharmacy_name || '',
      role: 'pharmacy',
      uid: formData.pharmacy_uid
    };

    await set(usersRef, userData);

    setIsAddModalOpen(false);
    toast.success('Pharmacy added successfully!');
  } catch (error) {
    console.error('Error adding pharmacy:', error);
    toast.error('Failed to add pharmacy. Please try again.');
  }
};

  const handleUpdatePharmacy = async (pharmacyId, formData) => {
    try {
      const pharmacyRef = ref(db, `pharmacy_details/${pharmacyId}`);
      await set(pharmacyRef, formData);
      setIsViewModalOpen(false);
      setIsEditing(false);
      toast.success('Pharmacy updated successfully!');
    } catch (error) {
      console.error('Error updating pharmacy:', error);
      toast.error('Failed to update pharmacy. Please try again.');
    }
  };
  

  const handleDeletePharmacy = async () => {
    if (selectedPharmacy) {
      try {
        await remove(ref(db, `pharmacy_details/${selectedPharmacy.id}`));
        setIsDeleteModalOpen(false);
        setIsViewModalOpen(false);
        setSelectedPharmacy(null);
        toast.success('Pharmacy deleted successfully!');
      } catch (error) {
        console.error('Error deleting pharmacy:', error);
        toast.error('Failed to delete pharmacy. Please try again.');
      }
    }
  };

  const paginatedPharmacies = filteredPharmacies.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);


  return (
    <div className="p-6 bg-gray-100">
      <SearchBar onSearch={handleSearch} onAddPharmacy={handleAddPharmacy} />

      <PharmacyTable 
        pharmacies={paginatedPharmacies}
        onView={(pharmacy) => {
          setSelectedPharmacy(pharmacy);
          setIsViewModalOpen(true);
          setIsEditing(false);
        }}
      />

      <Pagination 
        totalItems={filteredPharmacies.length}
        itemsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setRowsPerPage}
      />

      {isAddModalOpen && (
        <AddEditPharmacyModal 
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={handleSubmitPharmacy}
          pharmacy={null}
          isEditing={false}
        />
      )}

      {isViewModalOpen && selectedPharmacy && (
        <ViewPharmacyModal 
          isOpen={isViewModalOpen}
          onClose={() => {
            setIsViewModalOpen(false);
            setIsEditing(false);
          }}
          pharmacy={selectedPharmacy}
          isEditing={isEditing}
          onEdit={setIsEditing}
          onUpdate={handleUpdatePharmacy}
          onDelete={() => setIsDeleteModalOpen(true)}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteConfirmationModal 
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeletePharmacy}
        />
      )}
    </div>
  );
}
 
export default PharmacyPage;