import React from 'react';
import { Link } from 'react-router-dom';

function Dashboard({ user }) {
  return (
    <div className="container mx-auto px-4 py-8">
      <h3 className="text-gray-700 text-3xl font-medium mb-4">
        Welcome to Your Dashboard, {user?.email || 'Guest'}
      </h3>
      <div className="mt-4">
        <div className="flex flex-wrap -mx-6">
          <Link to="/agents" className="w-full md:w-1/2 px-6 py-6">
            <div className="bg-white rounded-lg shadow-lg p-6 hover:bg-gray-50 transition duration-300">
              <h4 className="text-xl font-semibold mb-2">Manage Agents</h4>
              <p className="text-gray-600">Add, edit, or remove agent information.</p>
            </div>
          </Link>
          <Link to="/pharmacies" className="w-full md:w-1/2 px-6 py-6">
            <div className="bg-white rounded-lg shadow-lg p-6 hover:bg-gray-50 transition duration-300">
              <h4 className="text-xl font-semibold mb-2">Manage Pharmacies</h4>
              <p className="text-gray-600">Add, edit, or remove pharmacy information.</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;