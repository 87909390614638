import React from 'react';

function AgentTable({ agents, onView }) {
  return (
    <table className="w-full bg-white shadow-md rounded">
      <thead>
        <tr className="bg-gray-200">
          <th className="p-2 text-center">Phone Number</th>
          <th className="p-2 text-center">Login Email</th>
          <th className="p-2 text-center">Name</th>
          <th className="p-2 text-center">Agent ID</th>
          <th className="p-2 text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {agents.map((agent) => (
          <tr key={agent.id} className="border-b">
            <td className="p-2 text-center">{agent.phone_number}</td>
            <td className="p-2 text-center">{agent.login_email}</td>
            <td className="p-2 text-center">{agent.name}</td>
            <td className="p-2 text-center">{agent.agent_id}</td>
            <td className="p-2 text-center">
              <button
                onClick={() => onView(agent)}
                className="bg-blue-500 text-white px-2 py-1 rounded"
              >
                View
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default AgentTable;