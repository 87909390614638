import React from 'react';

function PharmacyTable({ pharmacies, onView }) {
  return (
    <table className="w-full bg-white shadow-md rounded">
      <thead>
        <tr className="bg-gray-200">
          <th className="p-2 text-center">Pharmacy Name</th>
          <th className="p-2 text-center">Pharmacy ID</th>
          <th className="p-2 text-center">Contact Name</th>
          <th className="p-2 text-center">Contact Number</th>
          <th className="p-2 text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {pharmacies.map((pharmacy) => (
          <tr key={pharmacy.id} className="border-b">
            <td className="p-2 text-center">{pharmacy.pharmacy_name}</td>
            <td className="p-2 text-center">{pharmacy.pharmacy_id}</td>
            <td className="p-2 text-center">{pharmacy.contact_name}</td>
            <td className="p-2 text-center">{pharmacy.contact_number}</td>
            <td className="p-2 text-center">
              <button
                onClick={() => onView(pharmacy)}
                className="bg-blue-500 text-white px-2 py-1 rounded"
              >
                View
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}


export { PharmacyTable };