import React, { useState, useEffect } from 'react';
import { ref, onValue, push, set, remove, query, orderByChild, equalTo, get, runTransaction } from 'firebase/database';
import { db } from '../firebase';
import SearchBar from './agent/SearchBar';
import AgentTable from './agent/AgentTable';
import Pagination from './agent/Pagination';
import AddEditAgentModal from './agent/AddEditAgentModal';
import ViewAgentModal from './agent/ViewAgentModal';
import DeleteConfirmationModal from './agent/DeleteConfirmationModal';
import { toast } from 'react-toastify';

function AgentPage() {
  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const agentsRef = ref(db, 'agent_details');
    const unsubscribe = onValue(agentsRef, (snapshot) => {
      const data = snapshot.val();
      const agentList = data ? Object.entries(data).map(([key, value]) => ({ id: key, ...value })) : [];
      setAgents(agentList);
      setFilteredAgents(agentList);
    });

    return () => unsubscribe();
  }, []);

  const handleSearch = async (searchTerm, searchBy) => {
    if (!searchTerm) {
      setFilteredAgents(agents);
      return;
    }

    const agentsRef = ref(db, 'agent_details');
    const searchQuery = query(agentsRef, orderByChild(searchBy), equalTo(searchTerm));
    const snapshot = await get(searchQuery);
    const data = snapshot.val();
    const searchResults = data ? Object.entries(data).map(([key, value]) => ({ id: key, ...value })) : [];
    setFilteredAgents(searchResults);
  };

  const generateNextAgentId = async () => {
    const counterRef = ref(db, 'counters/agent_id');
    const result = await runTransaction(counterRef, (currentCount) => {
      return (currentCount || 0) + 1;
    });
    return result.snapshot.val();
  };

  const handleAddAgent = async (formData) => {
    try {
      const newAgentId = await generateNextAgentId();
      const newAgent = {
        name: formData.name,
        phone_number: formData.phone_number,
        login_email: formData.login_email,
        address: formData.address,
        agent_id: newAgentId,
        // uid: formData.uid
      };
        // Push to agent_details in Firebase
        const agentsRef = ref(db, 'agent_details');
        const newAgentRef = await push(agentsRef, newAgent);
  
        // Save to users table with role as 'agent'
        const agentUid = formData.agent_uid
        const user = {
          email: formData.login_email,
          name: formData.name,
          role: 'agent',
          uid: agentUid // use the newly created agent id as the uid
        };
        const usersRef = ref(db, `users/${agentUid}`);
        await set(usersRef, user);
  
        setIsAddModalOpen(false);
        toast.success('Agent added successfully!');
    } catch (error) {
      console.error('Error adding agent:', error);
      toast.error('Failed to add agent. Please try again.');
    }
  };

  const handleUpdateAgent = async (agentId, formData) => {
    try {
      const agentRef = ref(db, `agent_details/${agentId}`);
      await set(agentRef, formData);
      setIsViewModalOpen(false);
      setIsEditing(false);
      toast.success('Agent updated successfully!');
      
      // Update the local state to reflect the changes
      setAgents(prevAgents => 
        prevAgents.map(agent => 
          agent.id === agentId ? { ...agent, ...formData } : agent
        )
      );
      setFilteredAgents(prevAgents => 
        prevAgents.map(agent => 
          agent.id === agentId ? { ...agent, ...formData } : agent
        )
      );
    } catch (error) {
      console.error('Error updating agent:', error);
      toast.error('Failed to update agent. Please try again.');
    }
  };


  
  const handleDeleteAgent = async () => {
    try {
      if (selectedAgent && selectedAgent.id) {
        await remove(ref(db, `agent_details/${selectedAgent.id}`));
        setIsDeleteModalOpen(false);
        setIsViewModalOpen(false);
        setSelectedAgent(null);
        toast.success('Agent deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
      toast.error('Failed to delete agent. Please try again.');
    }
  };

  const paginatedAgents = filteredAgents.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div className="p-6 bg-gray-100">
      <SearchBar 
        onSearch={handleSearch} 
        onAddAgent={() => setIsAddModalOpen(true)} 
      />

      <AgentTable 
        agents={paginatedAgents}
        onView={(agent) => {
          setSelectedAgent(agent);
          setIsViewModalOpen(true);
          setIsEditing(false);
        }}
      />

      <Pagination 
        totalItems={filteredAgents.length}
        itemsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={setRowsPerPage}
      />

      <AddEditAgentModal 
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddAgent}
        isEditing={false}
      />

<ViewAgentModal 
        isOpen={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
          setIsEditing(false);
        }}
        agent={selectedAgent}
        isEditing={isEditing}
        onEdit={(editing) => setIsEditing(editing)}
        onUpdate={handleUpdateAgent}
        onDelete={() => setIsDeleteModalOpen(true)}
      />

      <DeleteConfirmationModal 
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteAgent}
      />
    </div>
  );
}

export default AgentPage;