import React, { useState } from 'react';

function SearchBar({ onSearch, onAddAgent }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchBy, setSearchBy] = useState('phone_number');

  const handleSearch = () => {
    onSearch(searchTerm, searchBy);
  };

  const selectStyle = {
    WebkitAppearance: 'none !important',
    MozAppearance: 'none !important',
    appearance: 'none !important',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'right .7em top 50% !important',
    backgroundSize: '.65em auto !important',
    paddingRight: '1.5em !important'
  };

  return (
    <div className="mb-6 flex items-center space-x-4">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by email address, phone number, or user UID"
        className="flex-grow p-2 border rounded"
      />
      <div className="relative inline-block" style={{width: '200px'}}>
        <select
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
          className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:border-gray-500"
          style={selectStyle}
        >
          <option value="phone_number">Phone Number</option>
          <option value="login_email">Email</option>
          <option value="name">Name</option>
          <option value="agent_id">Agent ID</option>
        </select>
      </div>
      <button onClick={handleSearch} className="bg-blue-500 text-white px-4 py-2 rounded">Search</button>
      <button onClick={onAddAgent} className="bg-green-500 text-white px-4 py-2 rounded">Add Agent</button>
    </div>
  );
}

export default SearchBar;