import React, { useState, useEffect } from "react";
import { getDatabase, ref, update } from "firebase/database";
import "./OrderStatus.css";

function OrderStatus({ status, orderId }) {
  const db = getDatabase();
  const [currentStatus, setCurrentStatus] = useState(status);

  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  const isNewOrder =
    status === "Cancelled/Abandoned by customer" ||
    status === "Ticket Closed by Customer Care";

  // Full sequence of stages
  const allStages = [
    "New Order",
    "Confirmed by Pharmacy",
    "Cancelled/Abandoned by customer",
    "Payment completed by customer",
    "Order Packed and Bill Uploaded by Pharmacy",
    "Order Picked by Delivery Partner",
    "Order Delivered By Delivery Partner",
    "Ticket Closed by Customer Care",
  ];

  // Stages we're using in this component
  const displayedStages = [
    "Payment completed by customer",
    "Order Delivered By Delivery Partner",
    "Ticket Closed by Customer Care",
  ];

  // Function to update order status in the database
  const updateOrderStatus = (newStatus) => {
    const orderRef = ref(db, `order_table/${orderId}`);
    update(orderRef, { order_status: newStatus })
      .then(() => {
        setCurrentStatus(newStatus);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  // Function to check if a stage is confirmed
  const isStageConfirmed = (stage) => {
    const currentIndex = allStages.indexOf(currentStatus);
    const stageIndex = allStages.indexOf(stage);
    return stageIndex <= currentIndex;
  };

  // Function to render action buttons for specific stages
  const renderActionButtons = (stage) => {
    const confirmed = isStageConfirmed(stage);
    return (
      <div className="order-status-buttons">
        <button
          onClick={() => updateOrderStatus(stage)}
          className={`order-status-button button-yes ${
            confirmed ? "disabled" : ""
          }`}
          disabled={confirmed}
        >
          Confirm
        </button>
      </div>
    );
  };

  return (
    <div className="order-status-container w-full">
      <h2 className="order-status-header">Order Status</h2>
      <table className="order-status-table">
        <thead>
          <tr>
            <th>Stage</th>
            <th>Status</th>
            {!isNewOrder && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {displayedStages.map((stage) => {
            const confirmed = isStageConfirmed(stage);
            return (
              <tr key={stage}>
                <td>{stage}</td>
                <td>{confirmed ? "Confirmed" : "Pending"}</td>
                {!isNewOrder && <td>{renderActionButtons(stage)}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default OrderStatus;
