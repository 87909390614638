import React from 'react';

function Pagination({ totalItems, itemsPerPage, currentPage, onPageChange, onRowsPerPageChange }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const selectStyle = {
    WebkitAppearance: 'none !important',
    MozAppearance: 'none !important',
    appearance: 'none !important',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'right .5em top 50% !important',
    backgroundSize: '.65em auto !important',
    paddingRight: '1.5em !important'
  };

  return (
    <div className="mt-4 flex justify-between items-center">
      <div className="flex items-center">
        <span className="mr-2">Rows per page:</span>
        <div className="relative inline-block" style={{width: '80px'}}>
          <select
            value={itemsPerPage}
            onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
            className="block w-full bg-white border border-gray-300 rounded py-1 px-2 leading-tight focus:outline-none focus:border-gray-500"
            style={selectStyle}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
          </select>
        </div>
      </div>
      <div>
        {currentPage} of {totalPages}
        <button
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
          className="ml-2 px-2 py-1 border rounded"
        >
          &lt;
        </button>
        <button
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="ml-2 px-2 py-1 border rounded"
        >
          &gt;
        </button>
      </div>
    </div>
  );
}

export default Pagination;