import React, { useState, useEffect } from "react";
import "./PharmacyConfirmationModal.css";

const PharmacyConfirmationModal = ({
  pharmacyConfirmation,
  medicines,
  onSave,
  onCancel,
}) => {
  const [editedConfirmation, setEditedConfirmation] =
    useState(pharmacyConfirmation);
  const [editedMedicines, setEditedMedicines] = useState(medicines);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    calculateTotalAmount();
  }, [editedConfirmation]);

  const handleInputChange = (index, field, value) => {
    const updatedConfirmation = [...editedConfirmation];
    updatedConfirmation[index] = {
      ...updatedConfirmation[index],
      [field]: value,
    };

    setEditedConfirmation(updatedConfirmation);

    // Update medicines if quantity or price is changed
    if (
      field === "quantity" ||
      field === "price" ||
      field === "subQty" ||
      field === "subPrice"
    ) {
      const updatedMedicines = [...editedMedicines];
      updatedMedicines[index] = { ...updatedMedicines[index], [field]: value };
      setEditedMedicines(updatedMedicines);
    }
  };

  const calculateTotalAmount = () => {
    const total = editedConfirmation.reduce((sum, item) => {
      if (item.available.toLowerCase() === "yes") {
        return (
          sum + (parseFloat(item.quantity) || 0) * (parseFloat(item.price) || 0)
        );
      } else {
        return (
          sum +
          (parseFloat(item.subQty) || 0) * (parseFloat(item.subPrice) || 0)
        );
      }
    }, 0);
    setTotalAmount(total.toFixed(2));
  };

  const handleSave = () => {
    onSave(editedConfirmation, editedMedicines, totalAmount);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Edit Pharmacy Confirmation</h2>
        <table>
          <thead>
            <tr>
              <th>Available</th>
              <th>Medicine Name</th>
              <th>QTY</th>
              <th>Price</th>
              <th>Substitution Available</th>
              <th>Sub. Medicine Name</th>
              <th>Sub QTY</th>
              <th>Sub Price</th>
            </tr>
          </thead>
          <tbody>
            {editedConfirmation.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor:
                    item.available.toLowerCase() === "yes"
                      ? "#e6ffe6"
                      : "#ffe6e6",
                }}
              >
                <td>
                  <select
                    value={item.available.toLowerCase() || "no"}
                    onChange={(e) =>
                      handleInputChange(index, "available", e.target.value)
                    }
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    value={item.name || ""}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                    disabled={item.available.toLowerCase() !== "yes"}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.quantity || ""}
                    onChange={(e) =>
                      handleInputChange(index, "quantity", e.target.value)
                    }
                    disabled={item.available.toLowerCase() !== "yes"}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.price || ""}
                    onChange={(e) =>
                      handleInputChange(index, "price", e.target.value)
                    }
                    disabled={item.available.toLowerCase() !== "yes"}
                  />
                </td>
                <td>
                  <select
                    value={item.substitutionAvailable.toLowerCase() || "no"}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "substitutionAvailable",
                        e.target.value
                      )
                    }
                    disabled={item.available.toLowerCase() === "yes"}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    value={item.subMedicineName || ""}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "subMedicineName",
                        e.target.value
                      )
                    }
                    disabled={item.available.toLowerCase() === "yes"}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.subQty || ""}
                    onChange={(e) =>
                      handleInputChange(index, "subQty", e.target.value)
                    }
                    disabled={item.available.toLowerCase() === "yes"}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.subPrice || ""}
                    onChange={(e) =>
                      handleInputChange(index, "subPrice", e.target.value)
                    }
                    disabled={item.available.toLowerCase() === "yes"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="total-amount">
          <strong>Total Amount: ₹{totalAmount}</strong>
        </div>
        <div className="modal-actions">
          <button onClick={handleSave} className="save-button">
            Confirm
          </button>
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PharmacyConfirmationModal;
