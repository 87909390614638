import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const AddEditPharmacyModal = ({ isOpen, onClose, onSubmit, pharmacy, isEditing }) => {
  const [formData, setFormData] = useState({
    pharmacy_name: '',
    address: '',
    pharmacy_id: '',
    city: '',
    location: '',
    location_instruction: '',
    contact_name: '',
    contact_number: '',
    date_of_joining: '',
    payment_bank: '',
    payment_mode: '',
    other_payment_details: '',
    account_number: '',
    login_email: '',
    login_phone_no: '',
    pharmacy_uid: ''
  });

  useEffect(() => {
    if (isEditing && pharmacy) {
      setFormData(pharmacy);
    } else {
      setFormData({
        pharmacy_name: '',
        address: '',
        pharmacy_id: '',
        city: '',
        location: '',
        location_instruction: '',
        contact_name: '',
        contact_number: '',
        date_of_joining: '',
        payment_bank: '',
        payment_mode: '',
        other_payment_details: '',
        account_number: '',
        login_email: '',
        login_phone_no: '',
        pharmacy_uid:''
      });
    }
  }, [isEditing, pharmacy]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  {isEditing ? 'Edit Pharmacy' : 'Add New Pharmacy'}
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                  {Object.entries(formData).map(([key, value]) => (
                    <div key={key} className="mb-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                      </label>
                      <input
                        type={key === 'date_of_joining' ? 'date' : key.includes('email') ? 'email' : 'text'}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required={['pharmacy_name', 'address', 'pharmacy_id', 'city', 'contact_name', 'contact_number', 'date_of_joining', 'login_email', 'login_phone_no', 'pharmacy_uid'].includes(key)}
                      />
                    </div>
                  ))}
                  <div className="col-span-2 mt-4 flex justify-end">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {isEditing ? 'Update Pharmacy' : 'Add Pharmacy'}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { AddEditPharmacyModal };