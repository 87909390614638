import React, { useEffect, useState } from "react";
import "./OrderProgress.css";
import { off, onValue, ref } from "firebase/database";
import { db } from "../../firebase";

const OrderProgress = ({ status, orderId }) => {
  const [currentStatus, setCurrentStatus] = useState(status);
  const stages = [
    "New Order",
    "Confirmed by Pharmacy",
    "Cancelled/Abandoned by customer",
    "Payment completed by customer",
    "Order Packed and Bill Uploaded by Pharmacy",
    "Order Picked by Delivery Partner",
    "Order Delivered By Delivery Partner",
    "Ticket Closed by Customer Care",
  ];

  const currentStageIndex = stages.indexOf(currentStatus);
  console.log(status);

  useEffect(() => {
    const orderRef = ref(db, `order_table/${orderId}`);

    // Set up the listener
    const unsubscribe = onValue(orderRef, (snapshot) => {
      if (snapshot.exists()) {
        const orderData = snapshot.val();
        setCurrentStatus(orderData.order_status);
      }
    });

    // Clean up the listener when the component unmounts
    return () => off(orderRef);
  }, [orderId, db]);

  return (
    <div className="order-progress">
      {stages.map((stage, index) => (
        <div
          key={index}
          className={`progress-step ${
            index <= currentStageIndex ? "active" : ""
          } ${index === currentStageIndex ? "current" : ""}`}
        >
          <div className="step-indicator"></div>
          <div className="step-label">{stage}</div>
        </div>
      ))}
    </div>
  );
};

export default OrderProgress;
