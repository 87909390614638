import React, { useState } from "react";
import "./PharmacyConfirmationDetails.css";
import PharmacyConfirmationModal from "./PharmacyConfirmationModal";

const PharmacyConfirmationDetails = ({
  pharmacyConfirmation,
  order,
  onUpdateOrder,
  ispast,
}) => {
  console.log("aysuh", pharmacyConfirmation);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const confirmations = Array.isArray(pharmacyConfirmation)
    ? pharmacyConfirmation
    : pharmacyConfirmation
    ? [pharmacyConfirmation]
    : [];

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);

  const handleSaveChanges = (updatedPharmacyConfirmation, updatedMedicines) => {
    onUpdateOrder(order.order_id, {
      pharmacy_confirmation: updatedPharmacyConfirmation,
      medicines: updatedMedicines,
    });
    closeEditModal();
  };
  const calculateTotal = (item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const price = parseFloat(item.price) || 0;
    const subQty = parseFloat(item.subQty) || 0;
    const subPrice = parseFloat(item.subPrice) || 0;

    return (
      item.substitutionAvailable === "Yes"
        ? subQty * subPrice
        : quantity * price
    ).toFixed(2);
  };

  const grandTotal = confirmations
    .reduce((total, item) => total + parseFloat(calculateTotal(item)), 0)
    .toFixed(2);

  return (
    <div className="pharmacy-confirmation-details">
      <div className="flex items-center justify-between py-3">
        <h3>Pharmacy Confirmation</h3>
        {pharmacyConfirmation && !ispast && (
          <button onClick={openEditModal} className="action-button edit">
            Edit
          </button>
        )}
      </div>

      <table>
        <thead>
          <tr>
            <th>Available</th>
            <th>Medicine Name</th>
            <th>QTY</th>
            <th>Price</th>
            <th>Substitution Available</th>
            <th>Sub. Medicine Name</th>
            <th>Sub QTY</th>
            <th>Sub Price</th>
            <th>Type</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {confirmations.map((item, index) => (
            <tr key={index}>
              <td>{item.available || "N/A"}</td>
              <td>{item.name || "N/A"}</td>
              <td>{item.quantity || "N/A"}</td>
              <td>{item.price || "N/A"}</td>
              <td>{item.substitutionAvailable || "No"}</td>
              <td>{item.subMedicineName || "N/A"}</td>
              <td>{item.subQty || "N/A"}</td>
              <td>{item.subPrice || "N/A"}</td>
              <td>
                {item.substitutionAvailable === "Yes"
                  ? "Substitute"
                  : "Original"}
              </td>
              <td>{calculateTotal(item)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="grand-total">
        <strong>Grand Total: {grandTotal}</strong>
      </div>
      {isEditModalOpen && (
        <PharmacyConfirmationModal
          pharmacyConfirmation={order.pharmacy_confirmation}
          medicines={order.medicines}
          onSave={handleSaveChanges}
          onCancel={closeEditModal}
        />
      )}
    </div>
  );
};

export default PharmacyConfirmationDetails;
