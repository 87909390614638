import React from 'react';
import './ActiveOrdersList.css';

const ActiveOrdersList = ({ orders, onSelectOrder, selectedOrderId }) => {
  return (
    <div className="active-orders-list">
      <h2>New Orders</h2>
      <ul>
        {orders.map((order) => (
          <li 
            key={order.order_id} 
            onClick={() => onSelectOrder(order.order_id)}
            className={order.order_id === selectedOrderId ? 'selected' : ''}
          >
            <div>Order id: {order.order_id}</div>
            <div>Time: {order.order_creation_datetime 
              ? new Date(order.order_creation_datetime).toLocaleString() 
              : 'Invalid Date'}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActiveOrdersList;