import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDR5honEd7kxHIbwLZa9bkaeZADXJm8oDw",
  authDomain: "dd01-2152a.firebaseapp.com",
  databaseURL: "https://dd01-2152a-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dd01-2152a",
  storageBucket: "dd01-2152a.appspot.com",
  messagingSenderId: "478228010963",
  appId: "1:478228010963:web:d5719009f7674474bf4126",
  measurementId: "G-C8V2RMNYPY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);

export { auth, db };