import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const AddEditAgentModal = ({ isOpen, onClose, onSubmit, agent, isEditing }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    login_email: '',
    address: '',
    agent_id: '',
    agent_uid:''
  });

  useEffect(() => {
    if (isEditing && agent) {
      setFormData(agent);
    } else {
      setFormData({
        name: '',
        phone_number: '',
        login_email: '',
        address: '',
        agent_uid:'',
        agent_id: 'Created automatically',
      });
    }
  }, [isEditing, agent]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const fieldLabels = {
    name: 'Full Name',
    phone_number: 'Phone Number',
    login_email: 'Login Email',
    address: 'Full Address',
    agent_id: 'Agent ID',
    agent_uid: 'Enter agent UID'
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  {isEditing ? 'Edit Agent' : 'Add New Agent'}
                </Dialog.Title>
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    {Object.entries(formData).map(([key, value]) => (
                      <div key={key} className="flex flex-col">
                        <label className="text-sm font-medium text-gray-700 mb-1">
                          {fieldLabels[key]}
                        </label>
                        <input
                          type={key === 'login_email' ? 'email' : 'text'}
                          name={key}
                          value={value}
                          onChange={handleInputChange}
                          className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                          required
                          readOnly={key === 'agent_id'}
                          placeholder={key === 'agent_id' ? 'Created automatically' : ''}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {isEditing ? 'Update Agent' : 'Add Agent'}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddEditAgentModal;